/* @purpose: check token.
  @response: return true/false.
  */
export function checkToken() {
  const token = localStorage.getItem("token");
  if (token === null || token === "") {
    return false;
  }
  return true;
}
