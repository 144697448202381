import React, { useEffect, useState } from 'react'
import { merchantDetails } from './../../../services/merchants.service'
import { merchantSettingDetails } from './../../../services/merchant-setting.service'
import './../../../bootstrap.min.css'
import './../../../index.css'
import { useHistory, useParams } from 'react-router-dom'
import {
  ERROR,
  MAILSEND,
  MAILSENDISSUE,
  SUCCESS,
  SIGNUP,
  PAYLORESEND
} from '../../../constant'
import Moment from 'react-moment'
import { mail } from '../../../services/mail.service'
import { resendPasswordData } from '../../../services/resend-password.service'
import { ToastContainer, toast } from 'react-toastify'
import './../../../../node_modules/react-toastify/dist/ReactToastify.css'
import { formatPhone } from '../../../common/format-phone'

export default function MerchantDetail() {
  const history = useHistory()
  const [merchants, setMerchants] = useState([])
  const [merchantsSetting, setMerchantsSetting] = useState([])
  const [tenderType, setTenderType] = useState([])
  const [tipNumber, setTipNumber] = useState([])
  const [phone, setPhone] = useState('N/A')
  const [status, setStatus] = useState('N/A')
  const { id } = useParams()
  useEffect(() => {
    merchantDetails(id).then((users) => {
      if (users === ERROR) {
        localStorage.clear()
        history.push('/')
      } else {
        setPhone(formatPhone(users.data.data[0].phone))
        const statusData = users.data.data[0].status
        setStatus(statusData.charAt(0).toUpperCase() + statusData.slice(1))
        setMerchants(users.data.data[0])
      }
    })
    merchantSettingDetails(id).then((usersSetting) => {
      if (usersSetting === ERROR) {
        localStorage.clear()
        history.push('/')
      } else {
        if (usersSetting.data.data.tender_type !== '') {
          setTenderType(usersSetting.data.data.tender_type.toString())
        }
        if (usersSetting.data.data.tip_number !== '') {
          setTipNumber(usersSetting.data.data.tip_number.toString())
        }
        setMerchantsSetting(usersSetting.data.data)
      }
    })
  }, [])

  /* @purpose: move to the mercahant list page.
  @response: move to the mercahant list page.
  */
  const back = async () => {
    history.push('/merchant-list')
  }

  /* @purpose: resend password in mail.
  @response: resend password in mail if error then it will display notification.
  */
  const resendPassword = async () => {
    const passwordRandorm = Math.random().toString(36).substring(7)
    const result = await resendPasswordData(merchants.user_id, passwordRandorm)
    if (result.data.status === SUCCESS) {
      mail(
        merchants.email,
        SIGNUP,
        PAYLORESEND,
        merchants.user_id,
        passwordRandorm
      )
      toast.success(MAILSEND, {
        position: 'top-center',
        autoClose: 3000,
        closeOnClick: true
      })
    } else {
      toast.error(MAILSENDISSUE, {
        position: 'top-center',
        autoClose: 3000,
        closeOnClick: true
      })
    }
  }

  return (
    <div className="content-wrap">
      <div className="page-title">
        <h2>Dashboard</h2>
      </div>
      <div id="merchant_details" className="card-box">
        <div className="card-box-title">
          <h4>Merchant Details</h4>
          <div>
            <a
              href={() => false}
              className="linkbtn"
              onClick={() => resendPassword()}
            >
              Resend Password
            </a>
            <a
              href={() => false}
              className="linkbtn marginl-3"
              onClick={() => back()}
            >
              Back
            </a>
          </div>
        </div>
        <div className="card-box-body">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <label>Name</label>
                <p>{merchants.name || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>User ID</label>
                <p>{merchants.user_id || 'N/A'}</p>
              </div>

              <div className="col-md-3">
                <label>Email</label>
                <p>{merchants.email || 'N/A'}</p>
              </div>

              <div className="col-md-3">
                <label>Phone</label>
                <p>{phone}</p>
              </div>
              <div className="col-md-3">
                <label>Company Name</label>
                <p>{merchants.company_name || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>Comapny URL</label>
                <p>{merchants.company_url || 'N/A'}</p>
              </div>

              <div className="col-md-3">
                <label>Street</label>
                <p>{merchants.street || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>City</label>
                <p>{merchants.city || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>State</label>
                <p>{merchants.state || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>Zip Code</label>
                <p>{merchants.zip_code || 'N/A'}</p>
              </div>

              <div className="col-md-3">
                <label>User Type</label>
                <p>{merchants.user_type || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>Status</label>
                <p>{status}</p>
              </div>
              <div className="col-md-3">
                <label>Created Date</label>
                <p>
                  <Moment format="MM/DD/YYYY">{merchants.created_date}</Moment>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="merchant_setting" className="card-box">
        <div className="card-box-title">
          <h4>Merchant Settings</h4>
        </div>
        <div className="card-box-body">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <label>Tip Status</label>
                <p>{merchantsSetting.tip_status || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>Tip Type</label>
                <p>{merchantsSetting.tip_type || 'N/A'}</p>
              </div>

              <div className="col-md-3">
                <label>Tip on receipt</label>
                <p>{merchantsSetting.tip_on_recipt || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>Tip Amount</label>
                <p>{tipNumber || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>Tax Status</label>
                <p>{merchantsSetting.tax_status || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>Tax Type</label>
                <p>{merchantsSetting.tax_type || 'N/A'}</p>
              </div>

              <div className="col-md-3">
                <label>Tax Amount</label>
                <p>{merchantsSetting.tax_number || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>Tender Type</label>
                <p>{tenderType || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>SVC Fee Status</label>
                <p>{merchantsSetting.svc_fee_status || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>SVC Fee Type</label>
                <p>{merchantsSetting.svc_fee_type || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>SVC Fee Name</label>
                <p>{merchantsSetting.svc_fee_name || 'N/A'}</p>
              </div>
              <div className="col-md-3">
                <label>SVC Fee Amount</label>
                <p>{merchantsSetting.svc_fee_number || 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
