import Sidebar from "./../sidebar/sidebar";
import Header from "./../header/header";
export default function Layout({ children }) {
  return (
    <div>
      <Header />
      <div className="content-body">
        <Sidebar />
      </div>
      {children}
    </div>
  );
}
