import { APIURL } from '../constant'
import http from '../interceptors/http-interceptor'
/* @purpose: get merchat details.
  @response: return list of users.
  */
export function merchantList() {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return http.get(`${APIURL}/users`, config)
}

/* @purpose: get merchat  details.
@params: id
  @response: return list of objects.
  */

export function merchantDetails(id) {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return http.get(`${APIURL}/user/${id}`, config)
}

/* @purpose: save merchant data.
@params: name, user_id, password, email, phone, company_name, company_url, street, city, state, zip_code, user_type, status
 @response: return status.
  */
export function addMerchant(
  name,
  user_id,
  password,
  email,
  phone,
  company_name,
  company_url,
  street,
  city,
  state,
  zip_code,
  user_type,
  status
) {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return http.post(
    `${APIURL}/user`,
    {
      name,
      user_id,
      password,
      email,
      phone,
      company_name,
      company_url,
      street,
      city,
      state,
      zip_code,
      user_type,
      status
    },
    config
  )
}
/* @purpose: update merchant data.
@params: id, name, email, phone, company_name, company_url, street, city, state, zip_code, status
 @response: return status.
  */
export function updateMerchant(
  id,
  name,
  email,
  phone,
  company_name,
  company_url,
  street,
  city,
  state,
  zip_code,
  status
) {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return http.put(
    `${APIURL}/user/${id}`,
    {
      name,
      email,
      phone,
      company_name,
      company_url,
      street,
      city,
      state,
      zip_code,
      status
    },
    config
  )
}
/* @purpose: save merchant setting data.
@params: user_id, tip_status, tip_type, tip_on_recipt, tax_status, tax_type, tax_number, svc_fee_status, svc_fee_type, svc_fee_name, svc_fee_number, tender_arr, tip_numbers
 @response: return status.
  */
export function PostMerchantSettings(
  user_id,
  tip_status,
  tip_type,
  tip_on_recipt,
  tax_status,
  tax_type,
  tax_number,
  svc_fee_status,
  svc_fee_type,
  svc_fee_name,
  svc_fee_number,
  auto_close_status,
  auto_close_time,
  tender_arr,
  tip_numbers
) {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return http.post(
    `${APIURL}/user-settings`,
    {
      user_id,
      tip_status,
      tip_type,
      tip_on_recipt,
      tax_status,
      tax_type,
      tax_number,
      svc_fee_status,
      svc_fee_type,
      svc_fee_name,
      svc_fee_number,
      auto_close_status,
      auto_close_time,
      tender_arr,
      tip_numbers
    },
    config
  )
}
/* @purpose: update merchant setting data.
@params: user_id, tip_status, tip_type, tip_on_recipt, tax_status, tax_type, tax_number, svc_fee_status, svc_fee_type, svc_fee_name, svc_fee_number, tender_arr, tip_numbers
 @response: return status.
  */
export function updateMerchantSettings(
  user_id,
  tip_status,
  tip_type,
  tip_on_recipt,
  tax_status,
  tax_type,
  tax_number,
  svc_fee_status,
  svc_fee_type,
  svc_fee_name,
  svc_fee_number,
  auto_close_status,
  auto_close_time,
  tender_arr,
  tip_numbers
) {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return http.put(
    `${APIURL}/user-setting/${user_id}`,
    {
      user_id,
      tip_status,
      tip_type,
      tip_on_recipt,
      tax_status,
      tax_type,
      tax_number,
      svc_fee_status,
      svc_fee_type,
      svc_fee_name,
      svc_fee_number,
      auto_close_status,
      auto_close_time,
      tender_arr,
      tip_numbers
    },
    config
  )
}

/* @purpose: update merchant setting data.
@params: user_id, tip_status, tip_type, tip_on_recipt, tax_status, tax_type, tax_number, svc_fee_status, svc_fee_type, svc_fee_name, svc_fee_number, tender_arr, tip_numbers
 @response: return status.
  */
export function deleteUser(id) {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return http.delete(`${APIURL}/user/${id}`, config)
}
