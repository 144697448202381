import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

export default function Header() {
  const history = useHistory();
  const [name, setName] = useState("Unknown");
  useEffect(() => {
    const token = localStorage.getItem("token");
    let decoded = jwt_decode(token);
    setName(decoded.user.name);
  }, []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  /* @purpose: logout users.
  @response: clear token and redirect login page.
  */
  const logout = () => {
    localStorage.clear();
    history.push("/");
  };
  return (
    <header>
      <div className="header">
        <div className="logo-box">
          <div className="logo">
            <img src="/assets/images/logo.svg" alt="" />
          </div>
        </div>
        <div className="user-section">
          <div className="dropdown">
            <div>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                {name}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => logout()}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
