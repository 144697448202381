import { APIURL } from '../constant'
import http from '../interceptors/http-interceptor'
/* @purpose: send mail.
@params: to, logo_image, type, subject, user_id, password
  @response: return mail response.
  */
export function mail(to, type, subject, user_id, password) {
  return http.post(`${APIURL}/mail`, {
    to,
    type,
    subject,
    user_id,
    password
  })
}
