import React, { useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { ADMIN } from './../../constant'

export default function Sidebar() {
  const [menuName, setMenuName] = useState('merchant')
  useEffect(() => {
    const token = localStorage.getItem('token')
    let decoded = jwt_decode(token)
    if (decoded.user.user_type === ADMIN) {
      setMenuName('Dashboard')
    } else {
      setMenuName('Transactions')
    }
  }, [])
  return (
    <aside className="sidemenupanel">
      <ul>
        <li>
          <a href={() => false} alt="" className="avtive">
            <img
              alt=""
              src="/assets/images/dashbaord-g.svg"
              className="g-icon"
            />{' '}
            <img
              alt=""
              src="/assets/images/dashbaord-w.svg"
              className="w-icon"
            />{' '}
            {menuName}
          </a>
        </li>
      </ul>
      <div className="menuimages">
        <img alt="" src="/assets/images/menu-bg.svg" />
      </div>
    </aside>
  )
}
