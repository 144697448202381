import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import './../../../../node_modules/react-toastify/dist/ReactToastify.css'
import './../../../bootstrap.min.css'
import './../../../index.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}))

export default function ResetPasswordSuccess() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <section
        className="login-section"
        style={{
          backgroundImage: `url("/assets/images/login-bg.svg")`
        }}
      >
        <div className="container-fluid">
          <div className="row h-100 align-items-center">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <div className="loginform">
                <div className="login-title">
                  <h2 className="removeMarginBottom">
                    Password changed successfully.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
