import React, { useState, useEffect } from 'react'
import {
  addMerchant,
  PostMerchantSettings,
  deleteUser
} from '../../../services/merchants.service'
import { mail } from '../../../services/mail.service'
import {
  SUCCESS,
  STATE,
  tenderListArr,
  SIGNUP,
  PAYLOSIGNUP,
  ENTERNAME,
  ENTERNAMELESSTHAN30,
  ENTERUSERID,
  ENTERUSERIDLESSTHAN10,
  ENTEREMAIL,
  ENTERVALIDEMAIL,
  ENTERPHONE,
  ENTERVALIDPHONE,
  ENTERCOMPANYNAME,
  ENTERCOMPANYNAMELESSTHAN25,
  ENTERSTREET,
  ENTERSTREETLESSTHAN25,
  ENTERCITY,
  ENTERCITYLESSTHAN25,
  ENTERSTATE,
  ENTERZIPCODE,
  ENTERNUMBER,
  ENTERVALIDZIPCODE,
  SELECTTIPTYPE,
  TIPLIST1BETWEEN1AND30,
  TIPLIST1BETWEEN1AND2500,
  TIPLIST2BETWEEN1AND30,
  TIPLIST2BETWEEN1AND2500,
  TIPLIST3BETWEEN1AND30,
  TIPLIST3BETWEEN1AND2500,
  TIPLIST4BETWEEN1AND30,
  TIPLIST4BETWEEN1AND2500,
  SELECTTIPONRECIPT,
  ENTERTAXAMOUNT,
  ENTERTAXAMOUNTBETWEEN1AND15,
  ENTERTAXAMOUNTBETWEEN1AND500,
  SELECTTENDERLIST,
  SELECTSVCTYPE,
  ENTERSVCFEENAME,
  ENTERSVCFEENAMELESSTHAN12,
  ENTERSVCFEEAMOUNT,
  ENTERSVCFEEAMOUNTBETWEEN1AND4,
  ENTERSVCFEEAMOUNTBETWEEN1AND10
} from '../../../constant'
import { ToastContainer, toast } from 'react-toastify'
import './../../../../node_modules/react-toastify/dist/ReactToastify.css'
import { useHistory } from 'react-router-dom'
import { checkToken } from '../../../services/check-authentication.service'
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import './../../../bootstrap.min.css'
import './../../../index.css'
import { formatPhone } from '../../../common/format-phone'

export default function AddMerchant() {
  const password = Math.random().toString(36).substring(7)
  const history = useHistory()
  let tipListData = ''
  const [name, setName] = useState('')
  const [userId, setUserId] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyUrl, setCompanyUrl] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [tipStatus, setTipStatus] = useState('disabled')
  const [tipType, setTipType] = useState('%')
  const [tipList1, setTipList1] = useState('')
  const [tipList2, setTipList2] = useState('')
  const [tipList3, setTipList3] = useState('')
  const [tipList4, setTipList4] = useState('')
  const [tipOnRecipt, setTipOnRecipt] = useState('disabled')
  const [taxStatus, setTaxStatus] = useState('disabled')
  const [taxType, setTaxType] = useState('%')
  const [taxNumber, setTaxNumber] = useState(0)
  const [tenderList, setTenderList] = useState('')
  const [svcFeeStatus, setSvcFeeStatus] = useState('disabled')
  const [svcFeeType, setSvcFeeType] = useState('%')
  const [svcFeeName, setSvcFeeName] = useState('')
  const [svcFeeNumber, setSvcFeeNumber] = useState(0)
  const [autoCloseStatus, setautoCloseStatus] = useState(false)
  const [addFirstTabClass, setAddFirstTabClass] = useState('active')
  const [addFirstTabContentClass, setAddFirstContentTabClass] =
    useState('active')
  const [addSecondTabClass, setAddSecondTabClass] = useState('')
  const [addSecondTabContentClass, setAddSecondContentTabClass] =
    useState('true')
    const [autoCloseTime, setautoCloseTime] = useState()
  useEffect(() => {
    const checkTokenBoolean = checkToken()
    if (!checkTokenBoolean) {
      history.push('/')
    }
  })
  /* @purpose: validate form.
@params: name, value
 @response: return error if error exists.
  */
  const validation = (name, value) => {
    switch (name) {
      case 'name':
        if (value === '') {
          setName('')
          return ENTERNAME
        } else if (value.length > 30) {
          setName(value)
          return ENTERNAMELESSTHAN30
        } else {
          setName(value)
          return ''
        }
        break
      case 'user_id':
        if (value === '') {
          setUserId('')
          return ENTERUSERID
        } else if (value.length > 10) {
          setUserId(value)
          return ENTERUSERIDLESSTHAN10
        } else {
          setUserId(value)
          return ''
        }
        break
      case 'email':
        if (value === '') {
          setEmail('')
          return ENTEREMAIL
        } else {
          const pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g)
          if (!pattern.test(value)) {
            setEmail(value)
            return ENTERVALIDEMAIL
          } else {
            setEmail(value)
            return ''
          }
        }
        break
      case 'phone':
        if (value === '') {
          setPhone('')
          return ENTERPHONE
        } else {
          const formatdPhone = formatPhone(value)
          value = formatdPhone.replace(/[^a-zA-Z0-9]/g, '')
          if (value.length !== 10) {
            setPhone(formatdPhone)
            return ENTERVALIDPHONE
          } else {
            setPhone(formatdPhone)
            return ''
          }
        }
        break
      case 'company_name':
        if (value === '') {
          setCompanyName('')
          return ENTERCOMPANYNAME
        } else if (value.length > 25) {
          setCompanyName(value)
          return ENTERCOMPANYNAMELESSTHAN25
        } else {
          setCompanyName(value)
          return ''
        }
        break
      case 'company_url':
        if (value === '') {
          setCompanyUrl('')
          return ''
        } else {
          setCompanyUrl(value)
          return ''
        }
        break
      case 'street':
        if (value === '') {
          setStreet('')
          return ENTERSTREET
        } else if (value.length > 25) {
          setStreet(value)
          return ENTERSTREETLESSTHAN25
        } else {
          setStreet(value)
          return ''
        }
        break
      case 'city':
        if (value === '') {
          setCity('')
          return ENTERCITY
        } else if (value.length > 25) {
          setCity(value)
          return ENTERCITYLESSTHAN25
        } else {
          setCity(value)
          return ''
        }
        break
      case 'state':
        if (value === '') {
          setState('')
          return ENTERSTATE
        } else {
          setState(value)
          return ''
        }
        break
      case 'zip_code':
        if (value === '') {
          setZipCode('')
          return ENTERZIPCODE
        } else {
          const pattern = new RegExp(/^[0-9\b]+$/)
          if (!pattern.test(value)) {
            return ENTERNUMBER
          } else if (value.length !== 5) {
            setZipCode(value)
            return ENTERVALIDZIPCODE
          } else {
            setZipCode(value)
            return ''
          }
        }
        break
      default:
        return ''
        break
    }
  }

  /* @purpose: check validation.
@params: event
 @response: return error if error exists otherwise move to the save api.
  */
  const checkValidationAndNext = async (event) => {
    event.preventDefault()
    const validationArr = []
    validationArr.push(
      { name: 'name', value: name },
      { name: 'user_id', value: userId },
      { name: 'email', value: email },
      { name: 'phone', value: phone },
      { name: 'company_name', value: companyName },
      { name: 'company_url', value: companyUrl },
      { name: 'street', value: street },
      { name: 'city', value: city },
      { name: 'state', value: state },
      { name: 'zip_code', value: zipCode }
    )
    let flag = false
    validationArr.forEach((val) => {
      const checkValidation = validation(val.name, val.value)
      if (
        flag === false &&
        (checkValidation !== '' || checkValidation === undefined)
      ) {
        flag = true
        toast.error(checkValidation, {
          position: 'top-center',
          autoClose: 3000,
          closeOnClick: true
        })
      }
    })
    if (flag) {
      return
    } else {
      changeTab('tab2')
    }
  }
  /* @purpose: Save merchant data.
  @params: event
 @response: save merchant data and get response.
  */
  const saveMerchantInformation = async () => {
    const userType = 'merchant'
    const status = 'active'
    const newPhone = phone.replace(/[^a-zA-Z0-9]/g, '')
    const result = await addMerchant(
      name,
      userId,
      password,
      email,
      newPhone,
      companyName,
      companyUrl,
      street,
      city,
      state,
      zipCode,
      userType,
      status
    )
    if (result.data.status === SUCCESS) {
      saveMerchantSettings(result.data.data.insertId)
    } else {
      toast.error(result.data.message, {
        position: 'top-center',
        autoClose: 3000,
        closeOnClick: true
      })
    }
  }
  /* @purpose: check merchant setting form error.
  @params: name, value
 @response: return error if error exists..
  */
  const settingValidation = (name, value) => {
    switch (name) {
      case 'tip_status':
        if (value === 'enabled') {
          setTipStatus(value)
          return ''
        } else {
          setTipType('%')
          setTipOnRecipt('disabled')
          setTipStatus(value)
          return ''
        }
        break
      case 'tip_type':
        if (value === '' && tipStatus === 'enabled') {
          setTipType('')
          return SELECTTIPTYPE
        } else {
          setTipType(value)
          return ''
        }
        break
      case 'tip_numbers':
        if (value === '' && tipStatus === 'enabled') {
          tipListData = value
          return ''
        } else {
          tipListData = value
          return ''
        }
        break
      case 'tip_numbers1':
        if (tipStatus === 'enabled' && (value === 0 || value === '')) {
          setTipList1('')
          return ''
        } else if (
          tipStatus === 'enabled' &&
          tipType === '%' &&
          (value < 0.1 || value > 30)
        ) {
          setTipList1(value)
          return TIPLIST1BETWEEN1AND30
        } else if (
          tipStatus === 'enabled' &&
          tipType === '$' &&
          (value > 2500 || value < 0.1)
        ) {
          setTipList1(value)
          return TIPLIST1BETWEEN1AND2500
        } else {
          setTipList1(value)
          return ''
        }
        break
      case 'tip_numbers2':
        if (value === '' && tipStatus === 'enabled') {
          setTipList2('')
          return ''
        } else if (
          tipStatus === 'enabled' &&
          tipType === '%' &&
          (value < 0.1 || value > 30)
        ) {
          setTipList2(value)
          return TIPLIST2BETWEEN1AND30
        } else if (
          tipStatus === 'enabled' &&
          tipType === '$' &&
          (value > 2500 || value < 0.1)
        ) {
          setTipList2(value)
          return TIPLIST2BETWEEN1AND2500
        } else {
          setTipList2(value)
          return ''
        }
        break
      case 'tip_numbers3':
        if (value === '' && tipStatus === 'enabled') {
          setTipList3('')
          return ''
        } else if (
          tipStatus === 'enabled' &&
          tipType === '%' &&
          (value < 0.1 || value > 30)
        ) {
          setTipList3(value)
          return TIPLIST3BETWEEN1AND30
        } else if (
          tipStatus === 'enabled' &&
          tipType === '$' &&
          (value > 2500 || value < 0.1)
        ) {
          setTipList3(value)
          return TIPLIST3BETWEEN1AND2500
        } else {
          setTipList3(value)
          return ''
        }
        break
      case 'tip_numbers4':
        if (value === '' && tipStatus === 'enabled') {
          setTipList4('')
          return ''
        } else if (
          tipStatus === 'enabled' &&
          tipType === '%' &&
          (value < 0.1 || value > 30)
        ) {
          setTipList4(value)
          return TIPLIST4BETWEEN1AND30
        } else if (
          tipStatus === 'enabled' &&
          tipType === '$' &&
          (value > 2500 || value < 0.1)
        ) {
          setTipList4(value)
          return TIPLIST4BETWEEN1AND2500
        } else {
          setTipList4(value)
          return ''
        }
        break
      case 'tip_on_recipt':
        if (value === '' && tipStatus === 'enabled') {
          setTipOnRecipt('')
          return SELECTTIPONRECIPT
        } else {
          setTipOnRecipt(value)
          return ''
        }
        break
      case 'tax_status':
        if (value === 'enabled') {
          setTaxStatus(value)
          return ''
        } else {
          setTaxNumber(0)
          setTaxType('%')
          setTenderList('')
          setTaxStatus(value)
          return ''
        }
        break
      case 'tax_type':
        if (value === 'enabled' && taxStatus === 'enabled') {
          setTaxType(value)
          return ''
        } else {
          setTaxType(value)
          return ''
        }
        break
      case 'tax_number':
        if (taxStatus === 'enabled' && (value === 0 || value === '')) {
          setTaxNumber(0)
          return ENTERTAXAMOUNT
        } else if (
          taxStatus === 'enabled' &&
          taxType === '%' &&
          (value < 0.1 || value > 15)
        ) {
          setTaxNumber(value)
          return ENTERTAXAMOUNTBETWEEN1AND15
        } else if (
          taxStatus === 'enabled' &&
          taxType === '$' &&
          (value > 500 || value < 0.1)
        ) {
          setTaxNumber(value)
          return ENTERTAXAMOUNTBETWEEN1AND500
        } else {
          setTaxNumber(value)
          return ''
        }
        break
      case 'tender_list':
        if (value === '') {
          setTenderList('')
          return SELECTTENDERLIST
        } else {
          setTenderList(value)
          return ''
        }
        break
      case 'svc_fee_status':
        if (value === 'enabled') {
          setSvcFeeStatus(value)
          return ''
        } else {
          setSvcFeeName('')
          setSvcFeeNumber(0)
          setSvcFeeType('%')
          setSvcFeeStatus(value)
          return ''
        }
        break
      case 'svc_fee_type':
        if (value === '' && svcFeeStatus === 'enabled') {
          setSvcFeeType('')
          return SELECTSVCTYPE
        } else {
          setSvcFeeType(value)
          return ''
        }
        break
      case 'svc_fee_name':
        if (value === '' && svcFeeStatus === 'enabled') {
          setSvcFeeName('')
          return ENTERSVCFEENAME
        } else if (svcFeeStatus === 'enabled' && value.length > 12) {
          setSvcFeeName(value)
          return ENTERSVCFEENAMELESSTHAN12
        } else {
          setSvcFeeName(value)
          return ''
        }
        break
      case 'svc_fee_number':
        if (svcFeeStatus === 'enabled' && (value === 0 || value === '')) {
          setSvcFeeNumber('')
          return ENTERSVCFEEAMOUNT
        } else if (
          svcFeeStatus === 'enabled' &&
          svcFeeType === '%' &&
          (value < 0.1 || value > 4)
        ) {
          setSvcFeeNumber(value)
          return ENTERSVCFEEAMOUNTBETWEEN1AND4
        } else if (
          svcFeeStatus === 'enabled' &&
          svcFeeType === '$' &&
          (value > 10 || value < 0.1)
        ) {
          setSvcFeeNumber(value)
          return ENTERSVCFEEAMOUNTBETWEEN1AND10
        } else {
          setSvcFeeNumber(value)
          return ''
        }
        case 'svc_toggle':
          if (value) {
            setautoCloseStatus(value)
          }
          else {
            setautoCloseStatus(false)
          }
          break
        case 'txt_time':
          if (value) {
            setautoCloseTime(value)
          }
          else {
            // setautoCloseTime('HH:MM')
          }
  
        break
      default:
        return ''
    }
  }

  /* @purpose: check merchant setting form validation.
  @params: event
 @response: return error if error exists otherwise move to the save api.
  */
  const checkValidationForSettings = async (event) => {
    event.preventDefault()
    let tipListStr = ''
    if (tipList1 !== 0 && tipList1 !== '') {
      tipListStr = tipListStr + '' + tipList1
    }
    if (tipList2 !== 0 && tipList2 !== '') {
      tipListStr = tipListStr + ',' + tipList2
    }
    if (tipList3 !== 0 && tipList3 !== '') {
      tipListStr = tipListStr + ',' + tipList3
    }
    if (tipList4 !== 0 && tipList4 !== '') {
      tipListStr = tipListStr + ',' + tipList4
    }

    const validationArr = []
    validationArr.push({ name: 'tip_numbers1', value: tipList1 })
    validationArr.push({ name: 'tip_numbers2', value: tipList2 })
    validationArr.push({ name: 'tip_numbers3', value: tipList3 })
    validationArr.push({ name: 'tip_numbers4', value: tipList4 })
    validationArr.push({ name: 'tip_numbers', value: tipListStr })
    validationArr.push({ name: 'tax_number', value: taxNumber })
    validationArr.push({ name: 'tender_list', value: tenderList })
    validationArr.push({ name: 'svc_fee_name', value: svcFeeName })
    validationArr.push({ name: 'svc_fee_number', value: svcFeeNumber })
    let flagSetting = false
    validationArr.forEach((val) => {
      const checkValidation = settingValidation(val.name, val.value)
      if (
        flagSetting === false &&
        (checkValidation !== '' || checkValidation === undefined)
      ) {
        flagSetting = true
        toast.error(checkValidation, {
          position: 'top-center',
          autoClose: 3000,
          closeOnClick: true
        })
      }
    })
    if (flagSetting) {
      return
    } else {
      saveMerchantInformation()
    }
  }
  /* @purpose: Save merchant setting.
  @params: insertId
 @response: return data after save
  */
  const saveMerchantSettings = async (insertedId) => {
    const result = await PostMerchantSettings(
      insertedId,
      tipStatus,
      tipType,
      tipOnRecipt,
      taxStatus,
      taxType,
      taxNumber,
      svcFeeStatus,
      svcFeeType,
      svcFeeName,
      svcFeeNumber,
      autoCloseStatus,
      autoCloseTime,   
      tenderList,      
      tipListData
    )
    if (result.data.status === SUCCESS) {
      mail(email, SIGNUP, PAYLOSIGNUP, userId, password)
      toast.success(result.data.message, {
        position: 'top-center',
        autoClose: 3000,
        closeOnClick: true
      })
    } else {
      deleteUser(insertedId)
      toast.error(result.data.message, {
        position: 'top-center',
        autoClose: 3000,
        closeOnClick: true
      })
    }
  }
  /* @purpose: change tabl merchant information to merchant setting.
  @params: tab
 @response: display form
  */
  const changeTab = async (tab) => {
    if (tab === 'tab2') {
      setAddSecondTabClass('active')
      setAddFirstTabClass('')
      setAddSecondContentTabClass('active show')
      setAddFirstContentTabClass('')
    } else {
      setAddSecondTabClass('')
      setAddFirstTabClass('active')
      setAddSecondContentTabClass('')
      setAddFirstContentTabClass('active')
    }
  }
  /* @purpose: redirect to merchant list.
 @response: move to merchant list
  */
  const back = async () => {
    history.push('/merchant-list')
  }
  /* @purpose: Need to dispaly 1st form.
 @response: dispaly 1st form
  */
  const merchantInformation = async () => {
    changeTab('tab1')
  }
  return (
    <div className="content-wrap">
      <div className="page-title">
        <h2>Merchant</h2>
      </div>
      <div id="AddUser" className="card-box">
        <div className="card-box-title">
          <h4>Add Merchant</h4>
          <a href={() => false} className="linkbtn" onClick={() => back()}>
            Back
          </a>
        </div>
        <div className="card-box-body">
          <div className="container">
            <br />
            <ul className="nav nav-pills justify-content-center" role="tablist">
              <li className="nav-item">
                <span
                  className={`nav-link ${addFirstTabClass}`}
                  data-toggle="pill"
                >
                  Merchant Information
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={`nav-link ${addSecondTabClass}`}
                  data-toggle="pill"
                >
                  Merchant Setting
                </span>
              </li>
            </ul>
            <div className="tab-content">
              <div
                id="home"
                className={`container tab-pane ${addFirstTabContentClass}`}
              >
                <br />
                <form
                  noValidate
                  onSubmit={checkValidationAndNext}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Name:<span className="errorColor">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Name"
                          className="form-control capsFirstLetter"
                          onChange={(e) => validation('name', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          User Id:<span className="errorColor">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter User ID"
                          className="form-control"
                          onChange={(e) =>
                            validation('user_id', e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Email:<span className="errorColor">*</span>
                        </label>
                        <input
                          type="email"
                          placeholder="Enter Email"
                          className="form-control"
                          onChange={(e) => validation('email', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Phone Number:<span className="errorColor">*</span>
                        </label>
                        <input
                          type="text"
                          value={phone}
                          placeholder="Enter Phone"
                          className="form-control"
                          onChange={(e) => validation('phone', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Company Name:<span className="errorColor">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Company name"
                          className="form-control capsFirstLetter"
                          onChange={(e) =>
                            validation('company_name', e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Company URL:</label>
                        <input
                          type="text"
                          placeholder="Enter URL"
                          className="form-control"
                          onChange={(e) =>
                            validation('company_url', e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Street:<span className="errorColor">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Street"
                          className="form-control capsFirstLetter"
                          onChange={(e) => validation('street', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          City:<span className="errorColor">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter City"
                          className="form-control capsFirstLetter"
                          onChange={(e) => validation('city', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          State:<span className="errorColor">*</span>
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) => validation('state', e.target.value)}
                        >
                          <option value="">Select</option>
                          {STATE.map(({ name }, index) => (
                            <option value={name}>{name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Zip Code:<span className="errorColor">*</span>
                        </label>
                        <input
                          type="number"
                          placeholder="Enter Zip code"
                          className="form-control"
                          onChange={(e) =>
                            validation('zip_code', e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="d-flex justify-content-end mt-3 btnWrap">
                        <button className="btn next">Save & Next</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div
                id="menu1"
                className={`container tab-pane fade ${addSecondTabContentClass}`}
              >
                <br />
                <form
                  noValidate
                  onSubmit={checkValidationForSettings}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Tip Status:<span className="errorColor">*</span>
                        </label>
                        <div className="RadioButtons">
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="tip_status"
                              value="enabled"
                              onClick={(e) =>
                                settingValidation('tip_status', e.target.value)
                              }
                            />
                            Enabled
                          </label>
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="tip_status"
                              value="disabled"
                              onClick={(e) =>
                                settingValidation('tip_status', e.target.value)
                              }
                              defaultChecked
                            />
                            Disabled
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Tip Type:<span className="errorColor">*</span>
                        </label>
                        <div className="RadioButtons">
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="tip_type"
                              value="%"
                              onClick={(e) =>
                                settingValidation('tip_type', e.target.value)
                              }
                              disabled={
                                tipStatus === 'disabled' ? 'disabled' : ''
                              }
                              defaultChecked
                            />
                            %
                          </label>
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="tip_type"
                              value="$"
                              onClick={(e) =>
                                settingValidation('tip_type', e.target.value)
                              }
                              disabled={
                                tipStatus === 'disabled' ? 'disabled' : ''
                              }
                            />
                            $
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Tip List 1:</label>
                        <input
                          type="number"
                          name="tip_numbers1"
                          placeholder="Enter tip amount."
                          className="form-control"
                          onChange={(e) =>
                            settingValidation('tip_numbers1', e.target.value)
                          }
                          disabled={tipStatus === 'disabled' ? 'disabled' : ''}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Tip List 2:</label>
                        <input
                          type="number"
                          name="tip_numbers2"
                          placeholder="Enter tip amount."
                          className="form-control"
                          onChange={(e) =>
                            settingValidation('tip_numbers2', e.target.value)
                          }
                          disabled={tipStatus === 'disabled' ? 'disabled' : ''}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Tip List 3:</label>
                        <input
                          type="number"
                          name="tip_numbers3"
                          placeholder="Enter tip amount."
                          className="form-control"
                          onChange={(e) =>
                            settingValidation('tip_numbers3', e.target.value)
                          }
                          disabled={tipStatus === 'disabled' ? 'disabled' : ''}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Tip List 4:</label>
                        <input
                          type="number"
                          name="tip_numbers4"
                          placeholder="Enter tip amount."
                          className="form-control"
                          onChange={(e) =>
                            settingValidation('tip_numbers4', e.target.value)
                          }
                          disabled={tipStatus === 'disabled' ? 'disabled' : ''}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Tip on Receipt:<span className="errorColor">*</span>
                        </label>
                        <div className="RadioButtons">
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="tip_on_recipt"
                              value="enabled"
                              onClick={(e) =>
                                settingValidation(
                                  'tip_on_recipt',
                                  e.target.value
                                )
                              }
                            />
                            Enabled
                          </label>
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="tip_on_recipt"
                              value="disabled"
                              onClick={(e) =>
                                settingValidation(
                                  'tip_on_recipt',
                                  e.target.value
                                )
                              }
                              defaultChecked
                            />
                            Disabled
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group"></div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Tax Status:<span className="errorColor">*</span>
                        </label>
                        <div className="RadioButtons">
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="tax_status"
                              value="enabled"
                              onClick={(e) =>
                                settingValidation('tax_status', e.target.value)
                              }
                            />
                            Enabled
                          </label>
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="tax_status"
                              value="disabled"
                              onClick={(e) =>
                                settingValidation('tax_status', e.target.value)
                              }
                              defaultChecked
                            />
                            Disabled
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Tax Type:<span className="errorColor">*</span>
                        </label>
                        <div className="RadioButtons">
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="tax_type"
                              value="%"
                              onClick={(e) =>
                                settingValidation('tax_type', e.target.value)
                              }
                              disabled={
                                taxStatus === 'disabled' ? 'disabled' : ''
                              }
                              defaultChecked
                            />
                            %
                          </label>
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="tax_type"
                              value="$"
                              onClick={(e) =>
                                settingValidation('tax_type', e.target.value)
                              }
                              disabled={
                                taxStatus === 'disabled' ? 'disabled' : ''
                              }
                            />
                            $
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Tax Amount:<span className="errorColor">*</span>
                        </label>
                        <input
                          type="number"
                          name="tax_number"
                          placeholder="Enter Tax amount"
                          className="form-control"
                          onChange={(e) =>
                            settingValidation('tax_number', e.target.value)
                          }
                          disabled={taxStatus === 'disabled' ? 'disabled' : ''}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Tender List:<span className="errorColor">*</span>
                        </label>
                        <MultiSelect
                          onChange={(e) => settingValidation('tender_list', e)}
                          options={tenderListArr}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          SVC Fee Status:<span className="errorColor">*</span>
                        </label>
                        <div className="RadioButtons">
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="svc_fee_status"
                              value="enabled"
                              onClick={(e) =>
                                settingValidation(
                                  'svc_fee_status',
                                  e.target.value
                                )
                              }
                            />
                            Enabled
                          </label>
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="svc_fee_status"
                              value="disabled"
                              onClick={(e) =>
                                settingValidation(
                                  'svc_fee_status',
                                  e.target.value
                                )
                              }
                              defaultChecked
                            />
                            Disabled
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          SVC Fee Type:<span className="errorColor">*</span>
                        </label>
                        <div className="RadioButtons">
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="svc_fee_type"
                              value="%"
                              onClick={(e) =>
                                settingValidation(
                                  'svc_fee_type',
                                  e.target.value
                                )
                              }
                              disabled={
                                svcFeeStatus === 'disabled' ? 'disabled' : ''
                              }
                              defaultChecked
                            />
                            %
                          </label>
                          <label className="mr-2">
                            <input
                              type="radio"
                              name="svc_fee_type"
                              value="$"
                              onClick={(e) =>
                                settingValidation(
                                  'svc_fee_type',
                                  e.target.value
                                )
                              }
                              disabled={
                                svcFeeStatus === 'disabled' ? 'disabled' : ''
                              }
                            />
                            $
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          SVC Fee Name:<span className="errorColor">*</span>
                        </label>
                        <input
                          type="text"
                          name="svc_fee_name"
                          placeholder="Enter SVC Fee Name"
                          className="form-control capsFirstLetter"
                          onChange={(e) =>
                            settingValidation('svc_fee_name', e.target.value)
                          }
                          disabled={
                            svcFeeStatus === 'disabled' ? 'disabled' : ''
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          SVC Fee Amount:<span className="errorColor">*</span>
                        </label>
                        <input
                          type="number"
                          name="svc_fee_number"
                          className="form-control"
                          placeholder="Enter SVC Fee Amount"
                          onChange={(e) =>
                            settingValidation('svc_fee_number', e.target.value)
                          }
                          disabled={
                            svcFeeStatus === 'disabled' ? 'disabled' : ''
                          }
                        />
                      </div>
                    </div>
                    {/* navneet */}
                    <div className="col-md-3">
                      <div className="form-group" >
                        <label>
                          Auto-Close:<span className="errorColor">*</span>
                        </label>
                        <div className="RadioButtons">
                          <label class="switch" >
                            <input type="checkbox" onChange={(e) =>
                              settingValidation(
                                'svc_toggle', e.currentTarget.checked
                              )
                            } />
                            <span class="slider round"></span>
                          </label>
                        </div>

                      </div>
                    </div>

                    <div className="col-md-3" >
                      <div className="form-group ">
                        <label>
                          Time:<span className="errorColor">*</span>
                        </label>
                        <input
                          value={autoCloseTime}
                          //type="textbox"
                          type="time"
                          name="txt_time"
                          class="Togglebtn"
                          placeholder="HH:MM"
                          className="form-control"
                          onChange={(e) =>
                            settingValidation('txt_time', e.target.value)
                          }
                           disabled={
                            autoCloseStatus === false ? "disabled" : ""
                          }                         
                        />
                      </div>
                    </div>
                     {/* navneet */}
                    <div className="col-md-12">
                      <div className="d-flex justify-content-end mt-3 btnWrap">
                        <span
                          className="btn next getColor"
                          onClick={(event) => merchantInformation()}
                        >
                          Back
                        </span>
                        <button className="btn next">Save</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
