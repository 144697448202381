import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { login } from './../../../services/login.service'
import { SUCCESS, ENTERUSERID, ENTERPASSWORD } from './../../../constant'
import { ToastContainer, toast } from 'react-toastify'
import './../../../../node_modules/react-toastify/dist/ReactToastify.css'
import { useHistory } from 'react-router-dom'
import './../../../bootstrap.min.css'
import './../../../index.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}))

export default function Login() {
  const classes = useStyles()
  const history = useHistory()
  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState('')
  const [userIdError, setUserIdError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  /* @purpose: check validation.
  @params: field name, value
  @response: return error if error exists.
  */
  const validation = async (name, value) => {
    if (name === 'user_id') {
      if (value === '') {
        setUserIdError(ENTERUSERID)
      } else {
        setUserIdError('')
        setUserId(value)
      }
    }
    if (name === 'password') {
      if (value === '') {
        setPasswordError(ENTERPASSWORD)
      } else {
        setPasswordError('')
        setPassword(value)
      }
    }
  }
  /* @purpose: send data using login api.
  @params: user_id, password
  @response: get response if success then set token otherwise show error notifcation.
  */
  const handleSubmit = async (event) => {
    event.preventDefault()
    const result = await login(userId, password)
    if (result.data.status === SUCCESS) {
      if (result.data.data.user_type === 'admin') {
        localStorage.setItem('token', result.data.data.token)
        history.push('/merchant-list')
      } else {
        localStorage.setItem('token', result.data.data.token)
        history.push('/transaction-list')
      }
    } else {
      toast.error(result.data.message, {
        position: 'top-center',
        autoClose: 3000,
        closeOnClick: true
      })
    }
  }

  /* @purpose: redirect to forgot password.
 @response: move to forgot password
  */
  const forgotPassword = async () => {
    history.push('/forgot-password')
  }
  return (
    <div className={classes.root}>
      <section
        className="login-section"
        style={{
          backgroundImage: `url("/assets/images/login-bg.svg")`
        }}
      >
        <div className="container-fluid">
          <div className="row h-100 align-items-center">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <div className="loginform">
                <div className="login-title">
                  <h2>Login</h2>
                </div>
                <form
                  className={classes.root}
                  noValidate
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="iconsrltv">
                    <input
                      type="text"
                      name="user_id"
                      className="form-control"
                      onChange={(e) => validation('user_id', e.target.value)}
                    />
                    <div className="errorColor">{userIdError}</div>
                    <label>Enter User ID</label>
                    <span className="login-icon">
                      <img src="/assets/images/user.svg" alt="" />
                    </span>
                  </div>
                  <div className="iconsrltv mb-5">
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      onChange={(e) => validation('password', e.target.value)}
                    />
                    <div className="errorColor">{passwordError}</div>
                    <label>Enter Password</label>
                    <span className="login-icon">
                      <img src="/assets/images/password.svg" alt="" />
                    </span>
                  </div>
                  <div className="login-btn">
                    <button className="btn loginbtn">
                      Login <img src="/assets/images/loginicon.svg" alt="" />
                    </button>
                  </div>
                  <div className="forgotpass">
                    <a href="" alt="" onClick={() => forgotPassword()}>
                      Forgot Password?
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  )
}
