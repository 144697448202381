import React, { useEffect, useState } from 'react'
import { transactionList } from './../../../services/transaction.service'
import Moment from 'react-moment'
import VisibilityIcon from '@material-ui/icons/Visibility'
import './../../../bootstrap.min.css'
import './../../../index.css'
import { useHistory } from 'react-router-dom'
import { ERROR, STATUS, ENTERCORRECTDATEFORMAT } from '../../../constant'
import Pagination from '@material-ui/lab/Pagination'
import jwt_decode from 'jwt-decode'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ToastContainer, toast } from 'react-toastify'

export default function TransactionList() {
  const history = useHistory()
  const [transactions, setTransactions] = useState([])
  const [currentTransactions, setCurrentTransactions] = useState([])
  const [searchTransactions, setSearchTransactions] = useState([])
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(10)
  const [totalPage, setTotalPage] = useState(5)
  let [rowId, setRowId] = useState(0)
  const [keyword, setkeyword] = useState('')
  const [dropDownData, setDropDownData] = useState('')
  const [terminalGroup, setTerminalGroup] = useState([])
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  )
  const [endDate, setEndDate] = useState(new Date())
  const [maxDate, setMaxDate] = useState(new Date())
  let terminalGroupData = []

  useEffect(() => {
    const token = localStorage.getItem('token')
    let decoded = jwt_decode(token)
    transactionList(decoded.user.id, 1, 10, 'all', '', '').then((data) => {
      if (data === ERROR) {
        localStorage.clear()
        history.push('/')
      } else {
        setTransactions(data.data.data)
        setCurrentTransactions(data.data.data.slice(0, rowsPerPage))
        setTotalPage(Math.ceil(data.data.data.length / rowsPerPage))
        if (data.data.data.length > 0) {
          data.data.data.forEach((value) => {
            const checkDropdownExist = terminalGroupData.filter(
              (e) => e.name === value.SN
            )
            if (checkDropdownExist.length === 0) {
              terminalGroupData.push({ name: value.SN })
            }
          })
          setTerminalGroup(terminalGroupData)
        }
        dateFilter('all', data.data.data)
      }
    })
  }, [])

  /* @purpose: search data from table list.
  @params: event
  @response: It will search data from table list and display filtered data.
  */

  const dateFilter = (name, event) => {
    if (name === 'from') {
      const getYear = new Date(event).getFullYear().toString()
      if (getYear.length === 4) {
        event.setHours(0, 0, 0, 0)
        setStartDate(event)
      } else {
        toast.error(ENTERCORRECTDATEFORMAT, {
          position: 'top-center',
          autoClose: 3000,
          closeOnClick: true
        })
      }
    }
    if (name === 'to') {
      const getYear = new Date(event).getFullYear().toString()
      if (getYear.length === 4) {
        setMaxDate(event)
        setEndDate(event)
      } else {
        toast.error(ENTERCORRECTDATEFORMAT, {
          position: 'top-center',
          autoClose: 3000,
          closeOnClick: true
        })
      }
    }
    if (name === 'all') {
      let data = event.filter((val) => {
        const date = new Date(val.created_date)
        return date >= startDate.setHours(0, 0, 0, 0) && date <= endDate
      })
      if (dropDownData !== '') {
        data = data.filter((e) => String(e.SN) === dropDownData)
      }
      if (keyword !== '') {
        data = data.filter(
          (e) =>
            e.transaction_id.includes(keyword) ||
            e.CustomerName.includes(keyword) ||
            e.CardType.includes(keyword) ||
            e.CardLast4.includes(keyword)
        )
      }
      setCurrentTransactions(data)
      setSearchTransactions(data)
      setTotalPage(Math.ceil(data.length / rowsPerPage))
      const value = 1
      const indexOfLastPost = value * rowsPerPage
      setRowId((value - 1) * rowsPerPage)
      const indexOfFirstPost = indexOfLastPost - rowsPerPage
      setCurrentTransactions(data.slice(indexOfFirstPost, indexOfLastPost))
      setPage(value)
    }
  }

  /* @purpose: search data from table list.
  @params: event
  @response: It will search data from table list and display filtered data.
  */
  const searchData = (name, event) => {
    event.preventDefault()
    let data = []
    if (name === 'search') {
      const keyword = event.target.value
      setkeyword(keyword)
      data = transactions.filter(
        (e) =>
          e.transaction_id.includes(keyword) ||
          e.CustomerName.includes(keyword) ||
          e.CardType.includes(keyword) ||
          e.CardLast4.includes(keyword)
      )
      if (dropDownData !== '') {
        data = data.filter((e) => String(e.SN) === dropDownData)
      }
      if (startDate !== '' && endDate !== '')
        data = data.filter((val) => {
          const date = new Date(val.created_date)
          return date >= startDate && date <= endDate
        })
    }
    if (name === 'terminal_id') {
      const terminalIdData = String(event.target.value)
      setDropDownData(terminalIdData)
      if (terminalIdData !== '') {
        data = transactions.filter((e) => String(e.SN) === terminalIdData)
      } else {
        data = transactions
      }
      if (keyword !== '') {
        data = data.filter(
          (e) =>
            e.transaction_id.includes(keyword) ||
            e.CustomerName.includes(keyword) ||
            e.CardType.includes(keyword) ||
            e.CardLast4.includes(keyword)
        )
      }
    }
    setCurrentTransactions(data)
    setSearchTransactions(data)
    setTotalPage(Math.ceil(data.length / rowsPerPage))
    const value = 1
    const indexOfLastPost = value * rowsPerPage
    setRowId((value - 1) * rowsPerPage)
    const indexOfFirstPost = indexOfLastPost - rowsPerPage
    setCurrentTransactions(data.slice(indexOfFirstPost, indexOfLastPost))
    setPage(value)
  }
  const handleChange = (event, value) => {
    const indexOfLastPost = value * rowsPerPage
    setRowId((value - 1) * rowsPerPage)
    const indexOfFirstPost = indexOfLastPost - rowsPerPage
    if (keyword !== '' || dropDownData !== '') {
      setCurrentTransactions(
        searchTransactions.slice(indexOfFirstPost, indexOfLastPost)
      )
    } else {
      setCurrentTransactions(
        transactions.slice(indexOfFirstPost, indexOfLastPost)
      )
    }
    setPage(value)
  }

  /* @purpose: move to view Transaction page.
  @params: id
  @response: move to view Transaction page
  */
  const view = async (id) => {
    history.push(`/transaction-detail/${id}`)
  }

  /* @purpose: export to excel file.
  @response: It will beexport to excel file.
  */
  const exportToExcel = () => {
    if (transactions.length === 0) {
      alert('No data available.')
    } else {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'
      const dataArr = transactions
      const arrData = []
      dataArr.forEach((element, i) => {
        const createdDate = new Date(element.created_date)
        const formatDate = `${
          createdDate.getMonth() + 1
        }/${createdDate.getDate()}/${createdDate.getFullYear()} ${createdDate.getHours()}:${createdDate.getMinutes()}:${createdDate.getSeconds()}`
        arrData.push({
          TerminalID: ' ' + element.SN,
          TransactionID: element.transaction_id,
          CustomerName:
            element.CustomerName !== '' ? element.CustomerName : 'N/A',
          Card:
            element.CardLast4 !== ''
              ? element.type + '*****' + element.CardLast4
              : 'N/A',
          TransactionType:
            element.CardType !== '' ? element.CardType + ' ' : 'N/A',
          SubTotal: '$' + element.SubTotal,
          TotalTip: '$' + element.TipAmount,
          Tax: '$' + element.TaxAmt,
          ServiceTax: '$' + element.SvcFee || 0,
          TotalAmount: '$' + element.TotalAmt,
          Status: element.status,
          Date: formatDate
        })
      })
      const ws = XLSX.utils.json_to_sheet(arrData)
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, 'tansaction_file' + fileExtension)
    }
  }

  return (
    <div className="content-wrap">
      <div className="page-title">
        <h2>Transactions</h2>
      </div>
      <div className="card-box">
        <div className="card-box-title">
          <h4>Transactions List</h4>
          <div>
            <a
              href={() => false}
              className="linkbtn"
              onClick={() => exportToExcel()}
            >
              Export to Excel
            </a>
          </div>
        </div>
        <div className=" text-sm">
          <div className="form-row">
            <div className="col-md-3">
              <div className="form-group">
                <input
                  type="text"
                  value={keyword}
                  placeholder="Search keyword"
                  className="form-control"
                  onChange={(e) => searchData('search', e)}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <select
                  value={dropDownData}
                  className="form-control"
                  onChange={(e) => searchData('terminal_id', e)}
                >
                  <option value="">Select Terminal Id</option>
                  {terminalGroup.map((e, key) => {
                    return (
                      <option key={key} value={e.name}>
                        {e.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6 datpiker-col form-group">
              <label>From: </label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={maxDate}
                onChange={(e) => dateFilter('from', e)}
              />
              <label>To: </label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                onChange={(e) => dateFilter('to', e)}
              />
              <div>
                <a
                  href={() => false}
                  className="linkbtn"
                  onClick={() => dateFilter('all', transactions)}
                >
                  Search Date Range
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="card-box-body UserData transData">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Terminal ID</th>
                <th scope="col">Transaction ID</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Transaction Type</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Card Last 4</th>
                <th scope="col">Date</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentTransactions.map((value, i) => (
                <tr key={i}>
                  <th scope="row">{++rowId}</th>
                  <td>{value.SN}</td>
                  <td>{value.transaction_id}</td>
                  <td>{value.CustomerName || 'N/A'}</td>
                  <td>{value.CardType || 'N/A'}</td>
                  <td>${value.TotalAmt}</td>
                  <td>{value.CardLast4 !== 'NA' ? value.CardLast4 : 'N/A'}</td>
                  <td>
                    <Moment format="MM/DD/YYYY h:mm a">
                      {value.created_date}
                    </Moment>
                  </td>
                  <td>
                    {value.status.charAt(0).toUpperCase() +
                      value.status.slice(1)}
                  </td>
                  <td className="text-center">
                    <VisibilityIcon
                      className="pointer"
                      onClick={(e) => view(value.id)}
                    ></VisibilityIcon>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination count={totalPage} page={page} onChange={handleChange} />
      </div>
      <ToastContainer />
    </div>
  )
}
