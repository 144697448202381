import { APIURL } from '../constant'
import http from '../interceptors/http-interceptor'
/* @purpose: get transation list.
  @response: return list of transations.
  */
export function transactionList(
  user_id,
  page_number,
  page_size,
  search,
  created_date,
  Terminal_Id
) {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return http.post(
    `${APIURL}/get-transaction`,
    { user_id, page_number, page_size, search, created_date, Terminal_Id },
    config
  )
}

/* @purpose: get transation  details.
@params: id
  @response: return list of objects.
  */

export function transactionDetail(id) {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return http.get(`${APIURL}/transaction/${id}`, config)
}
