import React, { useEffect, useState } from 'react'
import { merchantList } from './../../../services/merchants.service'
import Moment from 'react-moment'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import './../../../bootstrap.min.css'
import './../../../index.css'
import { useHistory } from 'react-router-dom'
import { ERROR, STATUS } from '../../../constant'
import Pagination from '@material-ui/lab/Pagination'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { formatPhoneNumber } from 'react-phone-number-input'
import { formatPhone } from '../../../common/format-phone'

export default function MerchantList() {
  const history = useHistory()
  const [merchants, setMerchants] = useState([])
  const [currentMerchants, setCurrentMerchants] = useState([])
  const [searchMerchants, setSearchMerchants] = useState([])
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(10)
  const [totalPage, setTotalPage] = useState(5)
  let [rowId, setRowId] = useState(0)
  const [keyword, setkeyword] = useState('')
  const [status, setStatus] = useState('')
  /* @purpose: export to excel file.
  @response: It will beexport to excel file.
  */
  const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    let dataArr = merchants
    let arrData = []
    dataArr.forEach((element, i) => {
      const createdDate = new Date(element.created_date)
      const formatDate = `${
        createdDate.getMonth() + 1
      }/${createdDate.getDate()}/${createdDate.getFullYear()}`
      arrData.push({
        Name: element.name,
        UserId: element.user_id,
        Email: element.email,
        Phone: ' ' + formatPhone(element.phone) + ' ',
        CompanyName: element.company_name,
        CompanyUrl: element.company_url,
        Status:
          element.status.charAt(0).toUpperCase() + element.status.slice(1),
        CreatedDate: formatDate
      })
    })
    const ws = XLSX.utils.json_to_sheet(arrData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, 'file' + fileExtension)
  }
  /* @purpose: search data from table list.
  @params: event
  @response: It will search data from table list and display filtered data.
  */
  const searchData = (name, event) => {
    event.preventDefault()
    let data = []
    if (name === 'search') {
      const keyword = event.target.value
      setkeyword(keyword)
      setStatus('')
      data = merchants.filter(
        (e) =>
          e.name.includes(keyword) ||
          e.user_id.includes(keyword) ||
          e.email.includes(keyword) ||
          e.company_name.includes(keyword) ||
          e.street.includes(keyword) ||
          e.city.includes(keyword) ||
          e.state.includes(keyword) ||
          e.company_url.includes(keyword)
      )
    }
    if (name === 'status') {
      setkeyword('')
      const statusData = event.target.value
      setStatus(statusData)
      if (statusData !== 'active/inactive') {
        data = merchants.filter((e) => e.status === statusData)
      } else {
        data = merchants
      }
    }
    setCurrentMerchants(data)
    setSearchMerchants(data)
    setTotalPage(Math.ceil(data.length / rowsPerPage))
    const value = 1
    const indexOfLastPost = value * rowsPerPage
    setRowId((value - 1) * rowsPerPage)
    const indexOfFirstPost = indexOfLastPost - rowsPerPage
    setCurrentMerchants(data.slice(indexOfFirstPost, indexOfLastPost))
    setPage(value)
  }
  const handleChange = (event, value) => {
    const indexOfLastPost = value * rowsPerPage
    setRowId((value - 1) * rowsPerPage)
    const indexOfFirstPost = indexOfLastPost - rowsPerPage
    if (keyword !== '') {
      setCurrentMerchants(
        searchMerchants.slice(indexOfFirstPost, indexOfLastPost)
      )
    } else {
      setCurrentMerchants(merchants.slice(indexOfFirstPost, indexOfLastPost))
    }
    setPage(value)
  }

  useEffect(() => {
    merchantList().then((users) => {
      if (users === ERROR) {
        localStorage.clear()
        history.push('/')
      } else {
        setMerchants(users.data.data)
        setCurrentMerchants(users.data.data.slice(0, rowsPerPage))
        setTotalPage(Math.ceil(users.data.data.length / rowsPerPage))
      }
    })
  }, [])

  /* @purpose: move to add merchant page.
  @response: move to add merchant page
  */
  const addMerchant = async () => {
    history.push('/add-merchant')
  }

  /* @purpose: move to view merchant page.
  @params: id
  @response: move to view merchant page
  */
  const view = async (id) => {
    history.push(`/merchant-detail/${id}`)
  }
  /* @purpose: move to edit merchant page.
  @params: id
  @response: move to edit merchant page
  */
  const edit = async (id) => {
    history.push(`/edit-merchant/${id}`)
  }

  return (
    <div className="content-wrap">
      <div className="page-title">
        <h2>Dashboard</h2>
      </div>
      <div className="card-box">
        <div className="card-box-title">
          <h4>Merchant List</h4>
          <div>
            <a
              href={() => false}
              className="linkbtn"
              onClick={() => exportToExcel()}
            >
              Export to Excel
            </a>
            <a
              href={() => false}
              className="linkbtn marginl-3"
              onClick={() => addMerchant()}
            >
              Add Merchant
            </a>
          </div>
        </div>
        <div className=" text-sm">
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <input
                  type="text"
                  value={keyword}
                  placeholder="Search keyword"
                  className="form-control"
                  onChange={(e) => searchData('search', e)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <select
                  value={status}
                  className="form-control"
                  onChange={(e) => searchData('status', e)}
                >
                  <option value="active/inactive">Status</option>
                  {STATUS.map(({ name, label }, index) => (
                    <option value={name}>{label}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="card-box-body UserData">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">User Id</th>
                <th scope="col">Email</th>
                <th scope="col">Company Name</th>
                <th scope="col">Street</th>
                <th scope="col">City</th>
                <th scope="col">State</th>
                <th scope="col">Status</th>
                <th scope="col">Created Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentMerchants.map((value, i) => (
                <tr key={i}>
                  <th scope="row">{++rowId}</th>
                  <td>{value.name}</td>
                  <td>{value.user_id}</td>
                  <td>{value.email}</td>
                  <td>{value.company_name}</td>
                  <td>{value.street}</td>
                  <td>{value.city}</td>
                  <td>{value.state}</td>
                  <td>
                    {value.status.charAt(0).toUpperCase() +
                      value.status.slice(1)}
                  </td>
                  <td>
                    <Moment format="MM/DD/YYYY">{value.created_date}</Moment>
                  </td>
                  <td>
                    <VisibilityIcon
                      className="pointer"
                      onClick={(e) => view(value.id)}
                    ></VisibilityIcon>
                    &nbsp;
                    <EditIcon
                      className="pointer"
                      onClick={(e) => edit(value.id)}
                    ></EditIcon>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination count={totalPage} page={page} onChange={handleChange} />
      </div>
    </div>
  )
}
