import React, { useEffect, useState } from 'react'
import { transactionDetail } from './../../../services/transaction.service'
import './../../../bootstrap.min.css'
import './../../../index.css'
import { useHistory, useParams } from 'react-router-dom'
import { ERROR } from '../../../constant'
import Moment from 'react-moment'
import { ToastContainer, toast } from 'react-toastify'
import './../../../../node_modules/react-toastify/dist/ReactToastify.css'

export default function TransactionDetail() {
  const history = useHistory()
  const [transactions, setTransactions] = useState([])
  const [refundList, setRefundList] = useState([])
  const [tipAdjustmentList, setTipAdjustmentList] = useState([])
  const { id } = useParams()
  let [rowId, setRowId] = useState(0)
  let [rowId2, setRowId2] = useState(0)
  let [refundListTotalAmount, setRefundListTotalAmount] = useState(0)
  let [tipAdjustmentListTotalAmount, setTipAdjustmentTotalAmount] = useState(0)
  useEffect(() => {
    transactionDetail(id).then((data) => {
      if (data === ERROR) {
        localStorage.clear()
        history.push('/')
      } else {
        setTransactions(data.data.data)
        setRefundList(data.data.data.refundList)
        setTipAdjustmentList(data.data.data.tipAdjustmentList)
        let tAmount = 0
        data.data.data.tipAdjustmentList.forEach((element) => {
          tAmount = Number(tAmount) + Number(element.tip_amount)
        })
        setTipAdjustmentTotalAmount(tAmount.toFixed(2))
        let rAmount = 0
        data.data.data.refundList.forEach((element) => {
          rAmount = Number(rAmount) + Number(element.refund_amount)
        })
        setRefundListTotalAmount(rAmount.toFixed(2))
      }
    })
  }, [])

  /* @purpose: move to the mercahant list page.
  @response: move to the mercahant list page.
  */
  const back = async () => {
    history.push('/transaction-list')
  }

  return (
    <div className="content-wrap">
      <div className="page-title">
        <h2>Transactions</h2>
      </div>
      <div id="merchant_details" className="card-box">
        <div className="card-box-title">
          <h4>Transaction Detail</h4>
          <div>
            <a
              href={() => false}
              className="linkbtn marginl-3"
              onClick={() => back()}
            >
              Back
            </a>
          </div>
        </div>
        <div className="card-box-body">
          <div className="container">
            <div className="row">
              <div className="col-md-6 lineHeight">
                <div className="col-md-12">
                  <table border="0" cellPadding="0" cellSpacing="0">
                    <tr>
                      <td>
                        <b>Transaction ID:</b>{' '}
                      </td>
                      <td className="pl-3">
                        {transactions.transaction_id || 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Date:</b>{' '}
                      </td>
                      <td className="pl-3">
                        <Moment format="MM/DD/YYYY h:mm a">
                          {transactions.created_date}
                        </Moment>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Customer Name:</b>{' '}
                      </td>
                      <td className="pl-3">
                        {transactions.CustomerName || 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Card:</b>{' '}
                      </td>
                      <td className="pl-3">
                        {transactions.type}*****
                        {transactions.CardLast4 || 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Transaction Type:</b>{' '}
                      </td>
                      <td className="pl-3">{transactions.CardType || 'N/A'}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-md-6 lineHeight">
                <div className="col-md-12">
                  <table border="0" cellPadding="0" cellSpacing="0">
                    <tr>
                      <td className="text-right">
                        <b>SubTotal:</b>
                      </td>
                      <td className="pl-3 text-right">
                        {' '}
                        ${transactions.SubTotal || 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-right">
                        <b>TotalTip:</b>
                      </td>
                      <td className="pl-3 text-right">
                        {' '}
                        $
                        {(
                          Number(transactions.TipAmount) +
                          Number(tipAdjustmentListTotalAmount)
                        ).toFixed(2) || 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-right">
                        <b>Tax:</b>
                      </td>
                      <td className="pl-3 text-right">
                        {' '}
                        ${transactions.TaxAmt || 'N/A'}
                      </td>
                    </tr>
                    {(() => {
                      if (
                        transactions.CardType === 'CREDIT' ||
                        transactions.CardType === 'credit' ||
                        transactions.CardType === 'Credit'
                      ) {
                        return (
                          <tr>
                            <td className="text-right">
                              <b>Service Tax:</b>
                            </td>
                            <td className="pl-3 text-right">
                              {' '}
                              ${transactions.SvcFee || 'N/A'}
                            </td>
                          </tr>
                        )
                      }
                    })()}

                    <tr>
                      <td className="text-right">
                        <b>Total Amount:</b>
                      </td>
                      <td className="pl-3 text-right">
                        {' '}
                        ${transactions.TotalAmt || 'N/A'}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(() => {
        if (refundList.length > 0) {
          return (
            <div id="merchant_setting" className="card-box">
              <div className="card-box-title">
                <h4>Refunded List</h4>
                <div>
                  <div className="linkbtn marginl-3">
                    Total Refunded: ${refundListTotalAmount}
                  </div>
                </div>
              </div>
              <div className="card-box-body UserData transData">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Refunded Amount</th>
                      <th scope="col">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {refundList.map((value, i) => (
                      <tr key={i}>
                        <th scope="row">{++rowId}</th>
                        <td>${value.refund_amount}</td>
                        <td>
                          <Moment format="MM/DD/YYYY h:mm a">
                            {value.created_date}
                          </Moment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )
        } else {
          return null
        }
      })()}

      {(() => {
        if (tipAdjustmentList.length > 0) {
          return (
            <div id="merchant_setting" className="card-box">
              <div className="card-box-title">
                <h4>Tip Adjustment List</h4>
                <div>
                  <div className="linkbtn marginl-3">
                    Total Tip Adjustment: ${tipAdjustmentListTotalAmount}
                  </div>
                </div>
              </div>
              <div className="card-box-body UserData transData">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Tip Amount</th>
                      <th scope="col">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tipAdjustmentList.map((value, i) => (
                      <tr key={i}>
                        <th scope="row">{++rowId2}</th>
                        <td>${value.tip_amount}</td>
                        <td>
                          <Moment format="MM/DD/YYYY h:mm a">
                            {value.created_date}
                          </Moment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )
        } else {
          return null
        }
      })()}
      <ToastContainer />
    </div>
  )
}
