import { APIURL } from '../constant'
import http from '../interceptors/http-interceptor'
/* @purpose: get merchat setting details.
@params: id
  @response: return list of objects.
  */
export function merchantSettingDetails(id) {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return http.get(`${APIURL}/user-setting/${id}`, config)
}
