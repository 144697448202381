import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { forgotPassword } from '../../../services/forgot-password.service'
import { SUCCESS, MAILSEND, ENTERUSERID } from '../../../constant'
import { ToastContainer, toast } from 'react-toastify'
import './../../../../node_modules/react-toastify/dist/ReactToastify.css'
import './../../../bootstrap.min.css'
import './../../../index.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}))

export default function ForgotPassword() {
  const classes = useStyles()
  const [userId, setUserId] = useState('')
  /* @purpose: validate form.
  @params: name, value
  @response: return error if exists.
  */
  const validation = (name, value) => {
    if (name === 'user_id') {
      if (value === '') {
        return ENTERUSERID
      } else {
        setUserId(value)
        return ''
      }
    }
  }
  /* @purpose: check validation and submit forgot password form.
  @params: user_id
  @response: return success message.
  */
  const handleSubmit = async (event) => {
    event.preventDefault()
    const validationArr = []
    validationArr.push({ name: 'user_id', value: userId })
    let flag = false
    validationArr.forEach((val) => {
      const checkValidation = validation(val.name, val.value)
      if (
        flag === false &&
        (checkValidation !== '' || checkValidation === undefined)
      ) {
        flag = true
        toast.error(checkValidation, {
          position: 'top-center',
          autoClose: 3000,
          closeOnClick: true
        })
      }
    })
    if (flag) {
      return
    } else {
      ForgotPasswordPost()
    }
  }
  /* @purpose: submit forgot password form.
  @params: user_id
  @response: return success message.
  */
  const ForgotPasswordPost = async () => {
    const result = await forgotPassword(userId)
    if (result.data.status === SUCCESS) {
      setUserId('')
      toast.success(MAILSEND, {
        position: 'top-center',
        autoClose: 3000,
        closeOnClick: true
      })
    } else {
      toast.error(result.data.message, {
        position: 'top-center',
        autoClose: 3000,
        closeOnClick: true
      })
    }
  }
  return (
    <div className={classes.root}>
      <section
        className="login-section"
        style={{
          backgroundImage: `url("/assets/images/login-bg.svg")`
        }}
      >
        <div className="container-fluid">
          <div className="row h-100 align-items-center">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <div className="loginform">
                <div className="login-title">
                  <h2>Forgot Password</h2>
                </div>
                <form
                  className={classes.root}
                  noValidate
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="iconsrltv iconsrltvForgot">
                    <input
                      type="user_id"
                      name="user_id"
                      value={userId}
                      className="form-control"
                      placeholder="Enter user Id."
                      onChange={(e) => validation('user_id', e.target.value)}
                    />
                    <div className="errorColor"></div>
                    <label>Enter User ID</label>
                    <span className="login-icon">
                      <img alt="" src="/assets/images/user.svg" />
                    </span>
                  </div>
                  <div className="login-btn">
                    <button className="btn loginbtn">
                      Send Mail{' '}
                      <img alt="" src="/assets/images/loginicon.svg" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  )
}
