import { APIURL } from '../constant'
import http from '../interceptors/http-interceptor'

/* @purpose: resend password on mail.
@params: user_id, password
 @response: return status.
  */
export function resendPasswordData(user_id, password) {
  const token = localStorage.getItem('token')
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return http.post(`${APIURL}/resend-password`, { user_id, password }, config)
}
