import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ResetPasswordService } from '../../../services/reset-password.service'
import {
  SUCCESS,
  PASSWORDRESET,
  ENTERPASSWORD,
  ENTER8DIGITPASSWORD,
  ENTERCONFIRMPASSWORD,
  ENTERSAMEPASSWORD
} from '../../../constant'
import { ToastContainer, toast } from 'react-toastify'
import './../../../../node_modules/react-toastify/dist/ReactToastify.css'
import { useParams } from 'react-router-dom'
import './../../../bootstrap.min.css'
import './../../../index.css'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}))

export default function ResetPassword() {
  const classes = useStyles()
  const history = useHistory()
  const [confirmPassword, setConfirmPassword] = useState('')
  const [password, setPassword] = useState('')
  const { token } = useParams()
  /* @purpose: validate form.
  @params: name, value
  @response: return error if exists.
  */
  const validation = (name, value) => {
    if (name === 'password') {
      let reduxData = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
      if (value === '') {
        return ENTERPASSWORD
      } else if (!reduxData.test(value)) {
        setPassword(value)
        return ENTER8DIGITPASSWORD
      } else {
        setPassword(value)
        return ''
      }
    }
    if (name === 'confirm_password') {
      if (value === '') {
        return ENTERCONFIRMPASSWORD
      } else {
        setConfirmPassword(value)
        return ''
      }
    }
    if (name === 'same') {
      if (password !== confirmPassword) {
        return ENTERSAMEPASSWORD
      } else {
        return ''
      }
    }
  }
  /* @purpose: check validation and submit reset form.
  @params: password, confirm password
  @response: return success message.
  */
  const handleSubmit = async (event) => {
    event.preventDefault()
    const validationArr = []
    validationArr.push(
      { name: 'password', value: password },
      { name: 'confirm_password', value: confirmPassword },
      { name: 'same', value: 'same' }
    )
    let flag = false
    validationArr.forEach((val) => {
      const checkValidation = validation(val.name, val.value)
      if (
        flag === false &&
        (checkValidation !== '' || checkValidation === undefined)
      ) {
        flag = true
        toast.error(checkValidation, {
          position: 'top-center',
          autoClose: 3000,
          closeOnClick: true
        })
      }
    })
    if (flag) {
      return
    } else {
      ResetPasswordPost()
    }
  }
  /* @purpose: submit reset form.
  @params: password, confirm password
  @response: return success message.
  */
  const ResetPasswordPost = async () => {
    const result = await ResetPasswordService(token, password, confirmPassword)
    if (result.data.status === SUCCESS) {
      toast.success(PASSWORDRESET, {
        position: 'top-center',
        autoClose: 3000,
        closeOnClick: true
      })
      history.push('/reset-password-success')
    } else {
      toast.error(result.data.message, {
        position: 'top-center',
        autoClose: 3000,
        closeOnClick: true
      })
    }
  }
  return (
    <div className={classes.root}>
      <section
        className="login-section"
        style={{
          backgroundImage: `url("/assets/images/login-bg.svg")`
        }}
      >
        <div className="container-fluid">
          <div className="row h-100 align-items-center">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <div className="loginform">
                <div className="login-title">
                  <h2>Reset Password</h2>
                </div>
                <form
                  className={classes.root}
                  noValidate
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="iconsrltv">
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Enter Password."
                      onChange={(e) => validation('password', e.target.value)}
                    />
                    <div className="errorColor"></div>
                    <label>Enter Password</label>
                    <span className="login-icon">
                      <img alt="" src="/assets/images/user.svg" />
                    </span>
                  </div>
                  <div className="iconsrltv mb-5">
                    <input
                      type="password"
                      name="confirm_password"
                      className="form-control"
                      placeholder="Enter confirm password."
                      onChange={(e) =>
                        validation('confirm_password', e.target.value)
                      }
                    />
                    <div className="errorColor"></div>
                    <label>Enter Confirm Password</label>
                    <span className="login-icon">
                      <img alt="" src="/assets/images/password.svg" />
                    </span>
                  </div>
                  <div className="login-btn">
                    <button className="btn loginbtn">
                      Reset Password{' '}
                      <img alt="" src="/assets/images/loginicon.svg" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  )
}
