import './index.css'
import { Route, Switch } from 'react-router-dom'
import MerchantList from './pages/merchant/merchant-list/merchant-list'
import Login from './pages/pages/login/login'
import Layout from './components/layout/layout'
import AddMerchant from './pages/merchant/add-merchant/add-merchant'
import EditMerchant from './pages/merchant/edit-merchant/edit-merchant'
import MerchantDetail from './pages/merchant/merchant-detail/merchant-detail'
import ResetPassword from './pages/pages/reset-password/reset-password'
import ForgotPassword from './pages/pages/forgot-password/forgot-password'
import ResetPasswordSuccess from './pages/pages/reset-password-success/reset-password-success'
import TransactionList from './pages/transaction/transaction-list/transaction-list'
import TransactionDetail from './pages/transaction/transaction-detail/transaction-detail'

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route
            path="/reset-password-success"
            component={ResetPasswordSuccess}
          />
          <Layout>
            <Route path="/merchant-list" component={MerchantList} />
            <Route path="/add-merchant" component={AddMerchant} />
            <Route path="/edit-merchant/:id" component={EditMerchant} />
            <Route path="/merchant-detail/:id" component={MerchantDetail} />
            <Route path="/transaction-list" component={TransactionList} />
            <Route
              path="/transaction-detail/:id"
              component={TransactionDetail}
            />
          </Layout>
        </Switch>
      </div>
    </div>
  )
}

export default App
