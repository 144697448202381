import axios from "axios";

axios.interceptors.response.use(undefined, (error) => {
  const expectedError =
    error.status && error.status >= 400 && error.status < 500;
  if (!expectedError) {
    console.log("An unexpected error occured");
  }
  return "error";
});

const APIOBJ = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

export default APIOBJ;
