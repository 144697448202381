import { APIURL } from '../constant'
import http from '../interceptors/http-interceptor'
/* @purpose: reset password and mail.
@params: token, password, confirm_password
 @response: return status.
  */
export function ResetPasswordService(token, password, confirm_password) {
  return http.post(`${APIURL}/reset-password`, {
    token,
    password,
    confirm_password
  })
}
