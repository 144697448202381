export const APIURL = 'https://pax.test.signapay.co' //https://pax.prod.signapay.co //https://pax.test.signapay.co //http://localhost:5000
export const ERROR = 'error'
export const SUCCESS = 'success'
export const ADMIN = 'admin'
export const SIGNUP = 'signup'
export const MAILSEND = 'Mail sent successfully.'
export const MAILSENDISSUE = 'There is some issue to send mail'
export const PASSWORDRESET = 'Password reset successfully.'
export const PAYLOSIGNUP = 'PAYLO Signup'
export const PAYLORESEND = 'PAYLO Resend Password'
export const ENTERNAME = 'Please enter Name.'
export const ENTERNAMELESSTHAN30 = 'Please enter Name less than 30 character.'
export const ENTERUSERID = 'Please enter User Id.'
export const ENTERPASSWORD = 'Please enter Password.'
export const ENTERCONFIRMPASSWORD = 'Please enter confirm Password.'
export const ENTERSAMEPASSWORD = 'Password and confirm password should be same.'
export const ENTER8DIGITPASSWORD =
  'Please enter 8 character alphanumric value and special character.'
export const ENTERUSERIDLESSTHAN10 =
  'Please enter User Id less than 10 character.'
export const ENTEREMAIL = 'Please enter Email.'
export const ENTERVALIDEMAIL = 'Please enter valid email address.'
export const ENTERPHONE = 'Please enter Phone Number.'
export const ENTERVALIDPHONE = 'Please enter valid phone number.'
export const ENTERCOMPANYNAME = 'Please enter Company Name.'
export const ENTERCOMPANYNAMELESSTHAN25 =
  'Please enter Company Name less than 25 character.'
export const ENTERSTREET = 'Please enter street.'
export const ENTERSTREETLESSTHAN25 =
  'Please enter street less than 25 character.'
export const ENTERCITY = 'Please enter city.'
export const ENTERCITYLESSTHAN25 = 'Please enter city less than 25 character.'
export const ENTERSTATE = 'Please enter state.'
export const ENTERZIPCODE = 'Please enter zip code.'
export const ENTERNUMBER = 'Please enter only number.'
export const ENTERVALIDZIPCODE = 'Please enter valid zip code.'
export const ENTERSTATUS = 'Please enter status.'
export const SELECTTIPTYPE = 'Please select tip type.'
export const ENTERCORRECTDATEFORMAT = 'Please enter correct date format.'
export const TIPLIST1BETWEEN1AND30 =
  'Please enter tip list1 number between 0.1 and 30.'
export const TIPLIST1BETWEEN1AND2500 =
  'Please enter tip list1 number between 0.1 and 2500.'
export const TIPLIST2BETWEEN1AND30 =
  'Please enter tip list2 number between 0.1 and 30.'
export const TIPLIST2BETWEEN1AND2500 =
  'Please enter tip list2 number between 0.1 and 2500.'
export const TIPLIST3BETWEEN1AND30 =
  'Please enter tip list3 number between 0.1 and 30.'
export const TIPLIST3BETWEEN1AND2500 =
  'Please enter tip list3 number between 0.1 and 2500.'
export const TIPLIST4BETWEEN1AND30 =
  'Please enter tip list4 number between 0.1 and 30.'
export const TIPLIST4BETWEEN1AND2500 =
  'Please enter tip list4 number between 0.1 and 2500.'
export const SELECTTIPONRECIPT = 'Please select tip on recipt.'
export const ENTERTAXAMOUNT = 'Please enter tax amount.'
export const ENTERTAXAMOUNTBETWEEN1AND15 =
  'Please enter tax amount between 0.1 and 15 .'
export const ENTERTAXAMOUNTBETWEEN1AND500 =
  'Please enter tax amount between 0.1 and 500.'
export const SELECTTENDERLIST = 'Please select tender list.'
export const SELECTSVCTYPE = 'Please select svc fee type.'
export const ENTERSVCFEENAME = 'Please enter svc fee name.'
export const ENTERSVCFEENAMELESSTHAN12 =
  'Please enter svc fee name less than 12 character.'
export const ENTERSVCFEEAMOUNT = 'Please enter svc fee amount.'
export const ENTERSVCFEEAMOUNTBETWEEN1AND4 =
  'Please enter svc fee amount between 0.1 and 4 .'
export const ENTERSVCFEEAMOUNTBETWEEN1AND10 =
  'Please enter svc fee amount between 0.1 and 10 .'
export const tenderListArr = [
  { label: 'Cash', value: 'Cash' },
  { label: 'EBT', value: 'EBT' },
  { label: 'Pin Debit', value: 'Pin Debit' },
  { label: 'Credit', value: 'Credit' }
]
export const STATUS = [
  {
    name: 'active',
    label: 'Active'
  },
  {
    name: 'inactive',
    label: 'Inactive'
  }
]
export const STATE = [
  {
    name: 'Alabama',
    code: 'AL'
  },
  {
    name: 'Alaska',
    code: 'AK'
  },
  {
    name: 'Arizona',
    code: 'AZ'
  },
  {
    name: 'Arkansas',
    code: 'AR'
  },
  {
    name: 'California',
    code: 'CA'
  },
  {
    name: 'Colorado',
    code: 'CO'
  },
  {
    name: 'Connecticut',
    code: 'CT'
  },
  {
    name: 'Delaware',
    code: 'DE'
  },
  {
    name: 'Florida',
    code: 'FL'
  },
  {
    name: 'Georgia',
    code: 'GA'
  },
  {
    name: 'Hawaii',
    code: 'HI'
  },
  {
    name: 'Idaho',
    code: 'ID'
  },
  {
    name: 'Illinois',
    code: 'IL'
  },
  {
    name: 'Indiana',
    code: 'IN'
  },
  {
    name: 'Iowa',
    code: 'IA'
  },
  {
    name: 'Kansas',
    code: 'KS'
  },
  {
    name: 'Kentucky',
    code: 'KY'
  },
  {
    name: 'Louisiana',
    code: 'LA'
  },
  {
    name: 'Maine',
    code: 'ME'
  },
  {
    name: 'Maryland',
    code: 'MD'
  },
  {
    name: 'Massachusetts',
    code: 'MA'
  },
  {
    name: 'Michigan',
    code: 'MI'
  },
  {
    name: 'Minnesota',
    code: 'MN'
  },
  {
    name: 'Mississippi',
    code: 'MS'
  },
  {
    name: 'Missouri',
    code: 'MO'
  },
  {
    name: 'Montana',
    code: 'MT'
  },
  {
    name: 'Nebraska',
    code: 'NE'
  },
  {
    name: 'Nevada',
    code: 'NV'
  },
  {
    name: 'New Hampshire',
    code: 'NH'
  },
  {
    name: 'New Jersey',
    code: 'NJ'
  },
  {
    name: 'New Mexico',
    code: 'NM'
  },
  {
    name: 'New York',
    code: 'NY'
  },
  {
    name: 'North Carolina',
    code: 'NC'
  },
  {
    name: 'North Dakota',
    code: 'ND'
  },
  {
    name: 'Ohio',
    code: 'OH'
  },
  {
    name: 'Oklahoma',
    code: 'OK'
  },
  {
    name: 'Oregon',
    code: 'OR'
  },
  {
    name: 'Pennsylvania',
    code: 'PA'
  },
  {
    name: 'Rhode Island',
    code: 'RI'
  },
  {
    name: 'South Carolina',
    code: 'SC'
  },
  {
    name: 'South Dakota',
    code: 'SD'
  },
  {
    name: 'Tennessee',
    code: 'TN'
  },
  {
    name: 'Texas',
    code: 'TX'
  },
  {
    name: 'Utah',
    code: 'UT'
  },
  {
    name: 'Vermont',
    code: 'VT'
  },
  {
    name: 'Virginia',
    code: 'VA'
  },
  {
    name: 'Washington',
    code: 'WA'
  },
  {
    name: 'West Virginia',
    code: 'WV'
  },
  {
    name: 'Wisconsin',
    code: 'WI'
  },
  {
    name: 'Wyoming',
    code: 'WY'
  }
]
